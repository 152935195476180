import { ReactNode, ThHTMLAttributes } from "react";
import { BaseSortDir } from "shared/design-system/models/base.model";

interface Props extends ThHTMLAttributes<HTMLTableHeaderCellElement> {
	children: ReactNode;

	onSort?: (property: string) => void;
	property?: string;
	sort?: string;
	sortDir?: BaseSortDir | undefined;
	className?: string;
}

const AppTableTh: React.FC<Props> = (props) => {
	const { children, onSort, property, sort, sortDir, className, ...other } =
		props;

	const handleClick = () => {
		if (!property) return;

		onSort?.(property);
	};

	return (
		<th
			{...other}
			className={
				(onSort ? "sortable " : " ") +
				(sort == property ? sortDir : " ") +
				" " +
				className
			}
			onClick={handleClick}
		>
			{children}
		</th>
	);
};
export default AppTableTh;
