import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "core/store";

const userMangerReducer = (state: RootState) => state.private.userManager;

export const userManagerTablePageSelector = createSelector(
	userMangerReducer,
	(state) => state.page
);

export const userManagerTablePageSizeSelector = createSelector(
	userMangerReducer,
	(state) => state.pageSize
);

export const userManagerTableSortSelector = createSelector(
	userMangerReducer,
	(state) => state.orderBy
);

export const userManagerTableSortDirSelector = createSelector(
	userMangerReducer,
	(state) => state.orderByDir
);

export const userManagerFiltersSelector = createSelector(
	userMangerReducer,
	(state) => state.filters
);

export const userManagerUsersSelector = createSelector(
	userMangerReducer,
	(state) => state.usersResponse
);
