import toast, { Toaster } from 'react-hot-toast';

const AppToast: React.FC = () => {
  const icons: { [key: string]: string } = {
    success: 'fas fa-check',
    error: 'fas fa-triangle-exclamation',
  };

  return (
    <Toaster position='top-center'>
      {t => {
        return (
          <div className={`max-w-md w-full`}>
            <div className={`alert alert-${t.type} flex justify-between`}>
              <div>
                <i className={`${icons[t.type]} me-3`} />

                {t.message?.toString()}
              </div>

              <div className='flex-grow-0'>
                <button onClick={() => toast.dismiss(t.id)}>
                  <i className='fas fa-close' />
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Toaster>
  );
};
export default AppToast;
