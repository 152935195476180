import AuthGuard from 'core/guards/auth.guard';
import AuthPage from 'pages/auth';
import PrivatePage from 'pages/private';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import KitchenSink from 'shared/design-system/kitchen-sink';
import { PathEnum } from './core/routes/path.enum';

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Navigate to={PathEnum.PRIVATE} />} />

        <Route path='ks' element={<KitchenSink />} />

        <Route path={`${PathEnum.AUTH}/*`} element={<AuthPage />} />

        <Route element={<AuthGuard />}>
          <Route path={`${PathEnum.PRIVATE}/*`} element={<PrivatePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRouter;
