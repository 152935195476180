export const appConfig = {
  // baseApiUrl: 'https://localhost:7208',
  baseApiUrl: 'https://api-svil.davidmaster.centrostudilovaas.com',

  /**
   * Definisci le opzioni del pigeSize da visualizzare nel table paginator
   */
  tablePageSizes: [10, 50, 100],

  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
};
