import React, {
	ChangeEventHandler,
	FocusEventHandler,
	HTMLInputTypeAttribute,
} from "react";
import "./style.scss";

interface Props {
	onChange: ChangeEventHandler<HTMLInputElement>;

	name?: string;
	label?: string;
	onBlur?: FocusEventHandler;
	type?: HTMLInputTypeAttribute;
	value?: string | number | string[] | undefined;
	disabled?: boolean;
	readOnly?: boolean;
	autoFocus?: boolean;
	isInvalid?: boolean;
	className?: string;
	placeholder?: string;
	error?: string;
	prefix?: string;
	suffix?: string;
}

const AppInput: React.FC<Props> = (props) => {
	const {
		disabled,
		name,
		readOnly,
		type,
		value,
		isInvalid,
		autoFocus,
		label,
		placeholder,
		error,
		onChange,
		onBlur,
		className,
		prefix,
		suffix,
	} = props;

	return (
		<div className={`form-control w-full app-input ${className}`}>
			{label && (
				<label className="label">
					<span className="label-text">{label}</span>
				</label>
			)}

			<label
				className={`input input-bordered flex items-center gap-2 ${
					isInvalid ? "input-error" : ""
				}`}
			>
				{prefix && <label>{prefix}</label>}

				<input
					type={type}
					className="grow"
					name={name}
					onChange={onChange}
					onBlur={onBlur}
					placeholder={placeholder}
					value={value}
					disabled={disabled}
					readOnly={readOnly}
					autoFocus={autoFocus}
				/>

				{suffix && <label>{suffix}</label>}
			</label>

			{isInvalid && (
				<label className="label">
					<span className="label-text-alt text-error text-semibold">
						{error}
					</span>
				</label>
			)}
		</div>
	);
};
export default AppInput;
