import { ReactNode } from "react";
import "./style.scss";

interface Props {
	children: ReactNode;

	onDelete?: () => void;
	deletable?: boolean;
}

const AppChip: React.FC<Props> = (props) => {
	const { children, deletable, onDelete } = props;

	return (
		<div className={`app-chip ${deletable ? "deletable" : ""}`}>
			{children}

			{deletable && (
				<div className="delete-btn" role="button" onClick={onDelete}>
					<i className="fas fa-times" />
				</div>
			)}
		</div>
	);
};
export default AppChip;
