import { AppSearchboxOptions } from 'shared/design-system/components/app-searchbox/types.models';

export const searchBoxConfig: AppSearchboxOptions[] = [
  {
    label: 'Nome',
    key: 'name',
  },
  {
    label: 'Utente',
    key: 'userEmail',
  },
];
