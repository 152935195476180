import { createSelector } from "@reduxjs/toolkit";
import { RootState } from ".";

const generalReducer = (state: RootState) => state.general;

export const generalLoaderSelector = createSelector(generalReducer, (state) => {
	return state.generalLoader > 0;
});

export const generalErrorSelector = createSelector(generalReducer, (state) => {
	return state.error;
});
