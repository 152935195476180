import { ReactNode, TableHTMLAttributes } from "react";
import "./style.scss";

interface Props extends TableHTMLAttributes<HTMLTableElement> {
	children: ReactNode;
}

const AppTable: React.FC<Props> = (props) => {
	const { children } = props;

	return (
		<div className="overflow-x-auto">
			<table className="table app-table" {...props}>
				{children}
			</table>
		</div>
	);
};
export default AppTable;
