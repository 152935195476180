import { LoginRequestDTO } from '@api/client';
import { authLogin } from 'core/auth/_redux/actions';
import { PathEnum } from 'core/routes/path.enum';
import { useAppDispatch } from 'core/store/hooks';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import AppButton from 'shared/design-system/components/app-button';
import AppInput from 'shared/design-system/components/app-input';
import Yup from 'shared/utility/validation.utility';

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = (values: LoginRequestDTO) => {
    dispatch(authLogin(values))
      .unwrap()
      .then(res => {
        if (res) {
          navigate('/');
        }
      });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required(),
        password: Yup.string().required(),
      })}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit: _handleSubmit,
      }) => {
        return (
          <form className='grid grid-cols-1 gap-3' onSubmit={_handleSubmit}>
            <h4 className='text-gray-900 text-semibold mb-3'>Accedi</h4>

            <AppInput
              label='Email'
              name='email'
              value={values?.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors?.email && touched?.email}
              error={errors?.email}
            />

            <AppInput
              type='password'
              label='Password'
              name='password'
              value={values?.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors?.password && touched?.password}
              error={errors?.password}
            />

            <a href={PathEnum.FORGOT_PASSWORD} className='link-primary'>
              Password Dimenticata ?
            </a>

            <AppButton type='submit'>Accedi</AppButton>
          </form>
        );
      }}
    </Formik>
  );
};
export default LoginPage;
