/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
import { AttivitaDTOFromJSON, AttivitaDTOToJSON, } from './AttivitaDTO';
/**
 * Check if a given object implements the AttivitaDTOListBaseResponse interface.
 */
export function instanceOfAttivitaDTOListBaseResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function AttivitaDTOListBaseResponseFromJSON(json) {
    return AttivitaDTOListBaseResponseFromJSONTyped(json, false);
}
export function AttivitaDTOListBaseResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : json['items'].map(AttivitaDTOFromJSON)),
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}
export function AttivitaDTOListBaseResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': value.items === undefined ? undefined : (value.items === null ? null : value.items.map(AttivitaDTOToJSON)),
        'count': value.count,
    };
}
