import { appConfig } from "core/configs/app.config";
import { ChangeEvent, useEffect, useState } from "react";
import AppSelect from "../app-select";
import "./style.scss";

const MAX_PAGE_VIEW = 5;
const MAX_PAGE_VIEW_TRUNC = Math.trunc(MAX_PAGE_VIEW / 2);

interface Props {
	elements?: number | null | undefined;
	activePage?: number;
	pageSize: number;
	onPageChange?: (page: number) => void;
	onPageSizeChange: (pageSize: number) => void;
}

const AppPaginator: React.FC<Props> = (props) => {
	const {
		elements,
		pageSize,
		activePage: _activePage,
		onPageChange,
		onPageSizeChange,
	} = props;

	const [activePage, setActivePage] = useState(_activePage || 1);
	const [pages, setPages] = useState(1);
	const [disablePrev, setDisablePrev] = useState(false);
	const [disableNext, setDisableNext] = useState(false);

	const handleSelect = (pageNumber: number) => {
		if (pages && pageNumber >= 0 && pageNumber <= pages) {
			setActivePage(pageNumber);
			onPageChange?.(pageNumber);
		}
	};

	const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
		onPageSizeChange(Number(event?.target.value));
	};

	const _getPages = () => {
		setPages(elements ? Math.ceil(elements / pageSize) : 0);
	};

	const _getMinPage = () => {
		if (pages) {
			if (
				activePage >= MAX_PAGE_VIEW_TRUNC &&
				activePage < pages - MAX_PAGE_VIEW_TRUNC
			) {
				return activePage - MAX_PAGE_VIEW_TRUNC;
			}

			if (activePage > pages - MAX_PAGE_VIEW) return pages - MAX_PAGE_VIEW + 1;
		}

		return 0;
	};

	const _getMaxPage = () => {
		if (pages) {
			if (
				activePage > MAX_PAGE_VIEW_TRUNC &&
				activePage <= pages - MAX_PAGE_VIEW_TRUNC
			) {
				return activePage + MAX_PAGE_VIEW_TRUNC;
			}

			if (activePage < MAX_PAGE_VIEW) return MAX_PAGE_VIEW;
		}

		return pages || 0;
	};

	useEffect(() => {
		handleSelect(1);
		_getPages();
	}, [elements, pageSize]);

	useEffect(() => {
		if (pages && pages > 1 && pages > MAX_PAGE_VIEW) {
			if (activePage == 1) setDisablePrev(true);
			else setDisablePrev(false);
			if (activePage == pages) setDisableNext(true);
			else setDisableNext(false);
		} else {
			setDisableNext(true);
			setDisablePrev(true);
		}
	}, [pages, activePage]);

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 app-paginator">
			<div className="app-paginator_page-size hidden md:block">
				<AppSelect onChange={handlePageSizeChange} size="sm">
					{appConfig.tablePageSizes?.map((p) => (
						<option value={p} key={`app-paginator_page-size-item-${p}`}>
							{p}
						</option>
					))}
				</AppSelect>
			</div>

			<div className="app-paginator_page">
				<div className="me-2 hidden md:block">{elements} Totali</div>

				<div className="app-paginator_page-selector">
					<div className="join paginator">
						<button className="join-item btn btn-sm">{"<"}</button>

						{Array.from(Array(pages).keys()).map((_, i) => {
							let pageNumber: number = i + 1;
							let minPage: number = _getMinPage();
							let maxPage: number = _getMaxPage();

							if (pages > MAX_PAGE_VIEW) {
								if (pageNumber < minPage || pageNumber > maxPage) return null;
							}

							return (
								<button
									className={`join-item btn btn-sm ${
										pageNumber === activePage ? "active" : ""
									}`}
									key={`pagination-item-${pageNumber}`}
									onClick={() => handleSelect(pageNumber)}
								>
									{pageNumber}
								</button>
							);
						})}

						<button className="join-item btn btn-sm">{">"}</button>
					</div>

					<AppSelect
						className="hidden md:block page-selector"
						value={activePage}
						onChange={(e) => handleSelect(Number(e.currentTarget.value))}
						size="sm"
					>
						{Array.from(Array(pages).keys()).map((_, i) => {
							let pageNumber: number = i;

							return (
								<option
									key={`page-selector-item-${pageNumber}`}
									value={pageNumber}
								>
									Pag {activePage} di {pages}
								</option>
							);
						})}
					</AppSelect>
				</div>
			</div>
		</div>
	);
};
export default AppPaginator;
