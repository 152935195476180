import { AttivitaDTO, AttivitaDTOListBaseResponse } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import { AppSearchboxActiveFilter } from 'shared/design-system/components/app-searchbox/types.models';
import { BaseSortDir } from 'shared/design-system/models/base.model';
import {
  activitiesAddFilter,
  activitiesDeleteFilter,
  activitiesGetList,
  activitiesSetTablePage,
  activitiesSetTablePageSize,
  activitiesSetTableSort,
} from './actions';

interface InitialValues {
  page: number;
  pageSize: number;
  orderBy: keyof AttivitaDTO | undefined;
  orderByDir: BaseSortDir | undefined;
  filters: AppSearchboxActiveFilter[];
  listResponse: AttivitaDTOListBaseResponse | undefined;
}

const initialState: InitialValues = {
  page: 1,
  pageSize: 10,
  orderBy: 'name',
  orderByDir: 'asc',
  filters: [],
  listResponse: undefined,
};

export const activitiesReducer = createReducer(initialState, builder => {
  builder
    .addCase(activitiesSetTablePage, (state, action) => {
      state.page = action.payload;
    })
    .addCase(activitiesSetTablePageSize, (state, action) => {
      state.page = 1;
      state.pageSize = action.payload;
    })
    .addCase(activitiesAddFilter, (state, action) => {
      state.filters.push(action.payload);
    })
    .addCase(activitiesDeleteFilter, (state, action) => {
      state.filters = state.filters.filter(f => f.key != action.payload);
    })
    .addCase(activitiesSetTableSort, (state, action) => {
      state.orderByDir =
        state.orderBy == action?.payload
          ? state.orderByDir == 'asc'
            ? 'desc'
            : 'asc'
          : state.orderByDir;
      state.orderBy = action?.payload;
    })
    .addCase(activitiesGetList.pending, (state, action) => {
      state.listResponse = undefined;
    })
    .addCase(activitiesGetList.fulfilled, (state, action) => {
      state.listResponse = action.payload;
    });
});
