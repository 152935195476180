import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

const reducer = (state: RootState) => state.private.activities;

export const activitiesTablePageSelector = createSelector(reducer, state => state.page);

export const activitiesTablePageSizeSelector = createSelector(reducer, state => state.pageSize);

export const activitiesTableSortSelector = createSelector(reducer, state => state.orderBy);

export const activitiesTableSortDirSelector = createSelector(reducer, state => state.orderByDir);

export const activitiesFiltersSelector = createSelector(reducer, state => state.filters);

export const activitiesListSelector = createSelector(reducer, state => state.listResponse);
