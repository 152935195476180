export namespace FileUtils {
  export const toBase64 = (file: File) =>
    new Promise<string | undefined>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        const base64Data = base64String.split(',')[1]; // Remove the data URL prefix
        resolve(base64Data);
      };

      reader.onerror = reject;
    });

  export const fromBase64 = (base64: string, fileName?: string): File => {
    var arr = base64.split(','),
      mime = arr?.[0]?.match(/:(.*?);/)?.[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName || '', { type: mime });
  };

  /**
   * Download File Base64
   * @param file
   * @param ext
   * @param fileName
   */
  export const downloadFile = (
    file: string,
    ext: string,
    fileName: string = new Date().toISOString(),
  ) => {
    let url = 'data:image/png;base64,' + file;
    let a = document.createElement('a');

    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = `${fileName}.${ext}`;
    a.click();

    window.URL.revokeObjectURL(url);

    a.remove();
  };

  export const scaleSize = (bytes: number): { value: string; um: 'kb' | 'mb' | 'gb' | '' } => {
    // if (String(bytes)?.length > 3) {
    // 	let _value = Math.round(value / 1000);

    // 	return { value: String(_value), um: "kb" };
    // }

    const k = 1024;
    const sizes: { val: number; um: 'kb' | 'mb' | 'gb' | '' }[] = [
      { val: 0, um: '' },
      { val: 1000, um: 'kb' },
      { val: 1000000, um: 'mb' },
      { val: 1000000000, um: 'gb' },
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return {
      value: String(Math.round(bytes / sizes[i]?.val)),
      um: sizes[i]?.um as 'kb' | 'mb' | 'gb' | '',
    };
  };
}
