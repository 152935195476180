import Dropzone, { Accept } from 'react-dropzone';
import { FileUtils } from 'shared/utility/files.utils';
import AppButton from '../app-button';

interface Props {
  file: File | undefined;
  onDrop: (file: File) => void;
  onDelete: () => void;

  accept?: 'image';
  label?: string;
  isInvalid?: boolean;
  error?: string;
}

const AppDropzone: React.FC<Props> = props => {
  const { file, onDrop, onDelete, label, error, isInvalid, accept } = props;

  const accepts: { [key: string]: Accept } = {
    image: {
      'image/*': ['.jpeg', '.png'],
    },
  };

  const handleDrop = (acceptedFiles: File[]) => {
    onDrop(acceptedFiles[0]);
  };

  return (
    <div className='app-dropzone'>
      {label && (
        <label className='label'>
          <span className='label-text'>{label}</span>
        </label>
      )}

      <Dropzone
        onDrop={handleDrop}
        maxFiles={1}
        {...(accept ? { accept: accepts[accept] } : {})}
      >
        {({ getRootProps, getInputProps }) => (
          <section className='container'>
            <div
              {...getRootProps({
                className:
                  'bg-gray-50 rounded-xl border border-dashed px-5 py-8 mb-2 flex flex-col items-center text-gray-500' +
                  (isInvalid ? ' border-error' : ''),
              })}
              role='button'
            >
              <input {...getInputProps()} />

              <div className='mb-3'>
                <i className='fas fa-cloud-upload-alt h2 text-inherit' />
              </div>

              <p className='section text-inherit'>
                Sposta i file qui o clicca per selezionare
              </p>
            </div>

            {file && (
              <aside>
                <h6 className='text-bold mb-2'>File Selezionato</h6>

                <ul>
                  <li>
                    <div className='flex items-center justify-between'>
                      <div className='flex items-center'>
                        <div className='avatar me-3'>
                          <div className='w-16 rounded'>
                            <img src={file ? URL.createObjectURL(file) : ''} />
                          </div>
                        </div>

                        <div>
                          <h6 className='text-semibold mb-2'>{file?.name}</h6>
                          <div className='section uppercase text-muted'>
                            {FileUtils.scaleSize(file?.size)?.value}{' '}
                            {FileUtils.scaleSize(file?.size)?.um}
                          </div>
                        </div>
                      </div>

                      <AppButton color='danger' onClick={() => onDelete()}>
                        <i className='fas fa-trash-alt' />
                      </AppButton>
                    </div>
                  </li>
                </ul>
              </aside>
            )}

            {isInvalid && (
              <label className='label'>
                <span className='label-text-alt text-error text-semibold'>{error}</span>
              </label>
            )}
          </section>
        )}
      </Dropzone>
    </div>
  );
};
export default AppDropzone;
