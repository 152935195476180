/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists } from '../runtime';
/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value) {
    let isInstance = true;
    return isInstance;
}
export function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
export function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'normalizedUserName': !exists(json, 'normalizedUserName') ? undefined : json['normalizedUserName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'normalizedEmail': !exists(json, 'normalizedEmail') ? undefined : json['normalizedEmail'],
        'emailConfirmed': !exists(json, 'emailConfirmed') ? undefined : json['emailConfirmed'],
        'passwordHash': !exists(json, 'passwordHash') ? undefined : json['passwordHash'],
        'securityStamp': !exists(json, 'securityStamp') ? undefined : json['securityStamp'],
        'concurrencyStamp': !exists(json, 'concurrencyStamp') ? undefined : json['concurrencyStamp'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'phoneNumberConfirmed': !exists(json, 'phoneNumberConfirmed') ? undefined : json['phoneNumberConfirmed'],
        'twoFactorEnabled': !exists(json, 'twoFactorEnabled') ? undefined : json['twoFactorEnabled'],
        'lockoutEnd': !exists(json, 'lockoutEnd') ? undefined : (json['lockoutEnd'] === null ? null : new Date(json['lockoutEnd'])),
        'lockoutEnabled': !exists(json, 'lockoutEnabled') ? undefined : json['lockoutEnabled'],
        'accessFailedCount': !exists(json, 'accessFailedCount') ? undefined : json['accessFailedCount'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'isAdmin': !exists(json, 'isAdmin') ? undefined : json['isAdmin'],
    };
}
export function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'userName': value.userName,
        'normalizedUserName': value.normalizedUserName,
        'email': value.email,
        'normalizedEmail': value.normalizedEmail,
        'emailConfirmed': value.emailConfirmed,
        'passwordHash': value.passwordHash,
        'securityStamp': value.securityStamp,
        'concurrencyStamp': value.concurrencyStamp,
        'phoneNumber': value.phoneNumber,
        'phoneNumberConfirmed': value.phoneNumberConfirmed,
        'twoFactorEnabled': value.twoFactorEnabled,
        'lockoutEnd': value.lockoutEnd === undefined ? undefined : (value.lockoutEnd === null ? null : value.lockoutEnd.toISOString()),
        'lockoutEnabled': value.lockoutEnabled,
        'accessFailedCount': value.accessFailedCount,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'isAdmin': value.isAdmin,
    };
}
