/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { LoginRequestDTOToJSON, LoginResponseDTOFromJSON, ResetPasswordRequestDTOToJSON, ResetPasswordResponseDTOFromJSON, UserDTOFromJSON, } from '../models';
/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
    /**
     */
    apiAuthForgotPasswordPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/Auth/ForgotPassword`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: requestParameters.body,
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    apiAuthForgotPasswordPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAuthForgotPasswordPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAuthLoginPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/Auth/Login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: LoginRequestDTOToJSON(requestParameters.loginRequestDTO),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAuthLoginPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAuthLoginPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAuthMePostRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/Auth/Me`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAuthMePost(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAuthMePostRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAuthResetPasswordPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/Auth/ResetPassword`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ResetPasswordRequestDTOToJSON(requestParameters.resetPasswordRequestDTO),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordResponseDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAuthResetPasswordPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAuthResetPasswordPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
