import { authForgotPassword } from 'core/auth/_redux/actions';
import { useAppDispatch } from 'core/store/hooks';
import { Formik } from 'formik';
import { useState } from 'react';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import SendEmailAnimation from 'shared/assets/lotties/email-send.json';
import AppButton from 'shared/design-system/components/app-button';
import AppInput from 'shared/design-system/components/app-input';
import * as Yup from 'yup';

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);

  const goBack = () => {
    navigate('/');
  };

  const handleSubmit = (values: FormValues) => {
    dispatch(authForgotPassword(values.email))
      .unwrap()
      .then(res => {
        if (res) setSuccess(true);
      });
  };

  return (
    <>
      {!success ? (
        <Formik<FormValues>
          initialValues={{
            email: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email().required(),
          })}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleSubmit: _handleSubmit,
            handleBlur,
            handleChange,
          }) => {
            return (
              <form onSubmit={_handleSubmit}>
                <h4 className='text-gray-900 text-semibold mb-3'>Password dimenticata</h4>
                <div className='caption mb-5'>
                  Inserisci la tua mail per resettare la tua password.
                </div>

                <AppInput
                  name='email'
                  label='Email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='mb-3'
                  isInvalid={!!errors.email && touched.email}
                />

                <AppButton type='submit'>Invia</AppButton>

                <div className='mt-5'>
                  <a role='button' className='link-primary' onClick={goBack}>
                    <i className='fas fa-arrow-left me-2' />
                    Indietro
                  </a>
                </div>
              </form>
            );
          }}
        </Formik>
      ) : (
        <>
          <Lottie
            options={{
              animationData: SendEmailAnimation,
              autoplay: true,
              loop: true,
              rendererSettings: {
                className: 'max-w-md',
              },
            }}
            style={{ display: 'flex', justifyContent: 'center' }}
          />

          <div className='caption'>
            Ti abbiamo inviato una mail con un link per recuperare la password!
          </div>

          <div className='mt-5'>
            <a role='button' className='link-primary' onClick={goBack}>
              <i className='fas fa-arrow-left me-2' />
              Indietro
            </a>
          </div>
        </>
      )}
    </>
  );
};
export default ForgotPasswordPage;

interface FormValues {
  email: string;
}
