/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { AttivitaCreateRequestToJSON, AttivitaDTOFromJSON, AttivitaDTOListBaseResponseFromJSON, } from '../models';
/**
 *
 */
export class AttivitaApi extends runtime.BaseAPI {
    /**
     */
    apiAttivitaEntIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiAttivitaEntIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/Attivita/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    apiAttivitaEntIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.apiAttivitaEntIdDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    apiAttivitaEntIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiAttivitaEntIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/Attivita/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AttivitaDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAttivitaEntIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAttivitaEntIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAttivitaEntIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.entId === null || requestParameters.entId === undefined) {
                throw new runtime.RequiredError('entId', 'Required parameter requestParameters.entId was null or undefined when calling apiAttivitaEntIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/Attivita/{entId}`.replace(`{${"entId"}}`, encodeURIComponent(String(requestParameters.entId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: AttivitaCreateRequestToJSON(requestParameters.attivitaCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AttivitaDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAttivitaEntIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAttivitaEntIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAttivitaGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.orderBy !== undefined) {
                queryParameters['orderBy'] = requestParameters.orderBy;
            }
            if (requestParameters.orderByDir !== undefined) {
                queryParameters['orderByDir'] = requestParameters.orderByDir;
            }
            if (requestParameters.name !== undefined) {
                queryParameters['name'] = requestParameters.name;
            }
            if (requestParameters.userEmail !== undefined) {
                queryParameters['userEmail'] = requestParameters.userEmail;
            }
            if (requestParameters.isLibrary !== undefined) {
                queryParameters['isLibrary'] = requestParameters.isLibrary;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/Attivita`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AttivitaDTOListBaseResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAttivitaGet(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAttivitaGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAttivitaGetLibraryGetRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/Attivita/getLibrary`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttivitaDTOFromJSON));
        });
    }
    /**
     */
    apiAttivitaGetLibraryGet(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAttivitaGetLibraryGetRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiAttivitaPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/Attivita`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AttivitaCreateRequestToJSON(requestParameters.attivitaCreateRequest),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => AttivitaDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiAttivitaPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiAttivitaPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
