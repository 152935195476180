import { ReactNode } from 'react';
import './style.scss';

interface Props {
  customText?: ReactNode | string;
}

const AppNoResults: React.FC<Props> = props => {
  const { customText } = props;

  return (
    <div className='app-no-results'>
      <h5 className='text-bold text-gray-400'>
        {customText || 'Nessun risultato trovato'}
      </h5>
    </div>
  );
};
export default AppNoResults;
