import { ChangeEventHandler, FocusEventHandler, ReactNode } from "react";
import "./styles.scss";

interface Props {
	children: ReactNode;

	label?: string;
	name?: string;
	value?: string | number | readonly string[] | undefined;
	onChange?: ChangeEventHandler<HTMLSelectElement>;
	onBlur?: FocusEventHandler;
	disabled?: boolean;
	autoFocus?: boolean;
	isInvalid?: boolean;
	className?: string;
	size?: "xs" | "sm" | "md" | "lg";
	error?: string;
}

const AppSelect: React.FC<Props> = (props) => {
	const {
		children,
		name,
		label,
		value,
		onChange,
		onBlur,
		isInvalid,
		error,
		disabled,
		autoFocus,
		className,
		size = "md",
	} = props;

	const sizes: { [key: string]: string } = {
		xs: "select-xs",
		sm: "select-sm",
		md: "select-md",
		lg: "select-lg",
	};

	return (
		<div className={`form-control w-full app-input ${className}`}>
			{label && (
				<label className="label">
					<span className="label-text">{label}</span>
				</label>
			)}

			<select
				className={`select select-bordered ${
					sizes[size]
				} app-select ${className} ${isInvalid ? "select-error" : ""}`}
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				disabled={disabled}
				// isInvalid={isInvalid}
				autoFocus={autoFocus}
			>
				{children}
			</select>

			{isInvalid && (
				<label className="label">
					<span className="label-text-alt text-error text-semibold">
						{error}
					</span>
				</label>
			)}
		</div>
	);
};
export default AppSelect;
