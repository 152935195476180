/* tslint:disable */
/* eslint-disable */
/**
 * David Master Api
 * db: david-master-svil
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { RegisterRequestDTOToJSON, UserCreateDTOToJSON, UserDTOFromJSON, UserDTOListBaseResponseFromJSON, } from '../models';
/**
 *
 */
export class UserManagerApi extends runtime.BaseAPI {
    /**
     */
    apiUserManagerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.pageSize !== undefined) {
                queryParameters['pageSize'] = requestParameters.pageSize;
            }
            if (requestParameters.orderBy !== undefined) {
                queryParameters['orderBy'] = requestParameters.orderBy;
            }
            if (requestParameters.orderByDir !== undefined) {
                queryParameters['orderByDir'] = requestParameters.orderByDir;
            }
            if (requestParameters.firstName !== undefined) {
                queryParameters['firstName'] = requestParameters.firstName;
            }
            if (requestParameters.lastName !== undefined) {
                queryParameters['lastName'] = requestParameters.lastName;
            }
            if (requestParameters.email !== undefined) {
                queryParameters['email'] = requestParameters.email;
            }
            if (requestParameters.role !== undefined) {
                queryParameters['role'] = requestParameters.role;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/UserManager`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOListBaseResponseFromJSON(jsonValue));
        });
    }
    /**
     */
    apiUserManagerGet(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiUserManagerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiUserManagerPostRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/UserManager`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: RegisterRequestDTOToJSON(requestParameters.registerRequestDTO),
            }, initOverrides);
            if (this.isJsonMime(response.headers.get('content-type'))) {
                return new runtime.JSONApiResponse(response);
            }
            else {
                return new runtime.TextApiResponse(response);
            }
        });
    }
    /**
     */
    apiUserManagerPost(requestParameters = {}, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiUserManagerPostRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiUserManagerUserIdDeleteRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling apiUserManagerUserIdDelete.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/UserManager/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     */
    apiUserManagerUserIdDelete(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.apiUserManagerUserIdDeleteRaw(requestParameters, initOverrides);
        });
    }
    /**
     */
    apiUserManagerUserIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling apiUserManagerUserIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/UserManager/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiUserManagerUserIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiUserManagerUserIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     */
    apiUserManagerUserIdPutRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.userId === null || requestParameters.userId === undefined) {
                throw new runtime.RequiredError('userId', 'Required parameter requestParameters.userId was null or undefined when calling apiUserManagerUserIdPut.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/UserManager/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: UserCreateDTOToJSON(requestParameters.userCreateDTO),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => UserDTOFromJSON(jsonValue));
        });
    }
    /**
     */
    apiUserManagerUserIdPut(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.apiUserManagerUserIdPutRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
