import AuthRouter from './AuthRouter';

const AuthPage: React.FC = () => {
  return (
    <div className='flex flex-col lg:flex-row flex-grow shrink-0 basis-auto'>
      <div className='flex lg:flex-1'>
        <div className='flex flex-col items-center justify-center pb-0 lg:pb-10 p-10 w-full'>
          <img
            className='mx-auto w-60 lg:w-96'
            src={`${process.env.PUBLIC_URL}/logo.png`}
          />

          <h3 className='text-gray-800 text-center text-bold mb-3'>
            Benvenuto in David Master
          </h3>

          <div className='text-gray-600 text-center text-semibold'>
            Accedi per gestire la tua applicazione.
          </div>
        </div>
      </div>

      <div className='flex flex-col lg:flex-row items-stretch lg:items-center justify-center lg:justify-end p-4 md:p-12'>
        <div className='card p-2 md:p-10 text-center lg:w-108'>
          <div className='card-body'>
            <AuthRouter />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuthPage;
