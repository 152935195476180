import {
  ApiUserManagerGetRequest,
  ApiUserManagerPostRequest,
  ApiUserManagerUserIdDeleteRequest,
  ApiUserManagerUserIdGetRequest,
  ApiUserManagerUserIdPutRequest,
  RegisterRequestDTO,
  UserCreateDTO,
  UserDTO,
  UserDTOListBaseResponse,
  UserManagerApi,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';
import { AppSearchboxActiveFilter } from 'shared/design-system/components/app-searchbox/types.models';

enum UserManagerReducer {
  setTablePage = '[USER MANAGER] Set table page',
  setTablePageSize = '[USER MANAGER] Set table page size',
  setTableSort = '[USER MANAGER] Set table sort',
  addFilter = '[USER MANAGER] Add filter',
  deleteFilter = '[USER MANAGER] Delete filter',
  getList = '[USER MANAGER] Get Users',
  getDetails = '[USER MANAGER] Get User Details',
  create = '[USER MANAGER] Create User',
  edit = '[USER MANAGER] Edit User',
}

export const userManagerSetTablePage = createAction<number>(
  UserManagerReducer.setTablePage,
);

export const userManagerSetTablePageSize = createAction<number>(
  UserManagerReducer.setTablePageSize,
);

export const userManagerSetTableSort = createAction<keyof UserDTO>(
  UserManagerReducer.setTableSort,
);

export const userManagerAddFilter = createAction<AppSearchboxActiveFilter>(
  UserManagerReducer.addFilter,
);

export const userManagerDeleteFilter = createAction<string>(
  UserManagerReducer.deleteFilter,
);

export const userManagerGetUsers = createAsyncThunk<
  UserDTOListBaseResponse | undefined,
  void,
  ThunkApiConfig
>(UserManagerReducer.getList, async (_, { getState }) => {
  try {
    let userManagerState = getState()?.private.userManager;

    let request: ApiUserManagerGetRequest = {
      page: userManagerState.page,
      pageSize: userManagerState.pageSize,
      orderBy: userManagerState.orderBy,
      orderByDir: userManagerState.orderByDir,
    };

    let filters: { [key in keyof ApiUserManagerGetRequest]: any } = {};

    userManagerState?.filters?.forEach(f => {
      filters[f.key as keyof ApiUserManagerGetRequest] = f.value;
    });

    request = { ...request, ...filters };

    return await new UserManagerApi(getConfiguration()).apiUserManagerGet(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const userManagerGetUserDetails = createAsyncThunk<
  UserDTO | undefined,
  number,
  ThunkApiConfig
>(UserManagerReducer.getDetails, async (id, {}) => {
  try {
    let request: ApiUserManagerUserIdGetRequest = {
      userId: id,
    };

    return await new UserManagerApi(getConfiguration()).apiUserManagerUserIdGet(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const userManagerCreateUser = createAsyncThunk<
  string | undefined,
  RegisterRequestDTO,
  ThunkApiConfig
>(UserManagerReducer.create, async (values, {}) => {
  try {
    let request: ApiUserManagerPostRequest = {
      registerRequestDTO: values,
    };

    return await new UserManagerApi(getConfiguration()).apiUserManagerPost(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const userManagerEditUser = createAsyncThunk<
  UserDTO | undefined,
  { id: number; request: UserCreateDTO },
  ThunkApiConfig
>(UserManagerReducer.edit, async (user, {}) => {
  try {
    let request: ApiUserManagerUserIdPutRequest = {
      userId: user.id,
      userCreateDTO: user.request,
    };

    return await new UserManagerApi(getConfiguration()).apiUserManagerUserIdPut(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const userManagerDeleteUser = createAsyncThunk<
  void | undefined,
  number,
  ThunkApiConfig
>(UserManagerReducer.edit, async (userId, {}) => {
  try {
    let request: ApiUserManagerUserIdDeleteRequest = {
      userId,
    };

    return await new UserManagerApi(getConfiguration()).apiUserManagerUserIdDelete(
      request,
    );
  } catch (e) {
    errorHandler(e);
  }
});
