import { createReducer } from "@reduxjs/toolkit";
import { resetGeneralError, setGeneralError } from "./actions";

export interface GeneralReducerType {
	generalLoader: number;
	error: any;
}

const initialState: GeneralReducerType = {
	generalLoader: 0,
	// error: ErrorResponseDtoFromJSON({}),
	error: undefined,
};

const whitelistLoader: string[] = [];

export const generalReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(setGeneralError, (state, action) => {
			state.error = action.payload;
		})
		.addCase(resetGeneralError, (state) => {
			state.error = initialState.error;
		})

		.addMatcher(
			(action) => action.type.endsWith("/pending"),
			(state, action) => {
				if (!whitelistLoader.includes(action.type)) {
					state.generalLoader = state.generalLoader + 1;
				}
			}
		)
		.addMatcher(
			(action) =>
				action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected"),
			(state, action) => {
				if (!whitelistLoader.includes(action.type)) {
					state.generalLoader = state.generalLoader - 1;
				}
			}
		);
});

export {};
