import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../../core/store/hooks';
import { layoutSetPageTitle } from '../../_redux/actions';
import AppBreadcrumbs from '../app-breadcrumbs';
import AppButton from '../app-button';
import './style.scss';

interface Props {
  title: string | null | undefined;
  children: ReactNode;

  subtitle?: string | undefined;
  className?: string;
  goBack?: boolean;
  pathNames?: string[];
  actions?: ReactNode;
}

const AppPage: React.FC<Props> = props => {
  const { title, subtitle, children, className, goBack, pathNames, actions } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (title) dispatch(layoutSetPageTitle(title));
  }, [title]);

  return (
    <div className={'app-page container-fluid ' + className}>
      <div className='app-page-header'>
        <div className='app-page-title'>
          {goBack && (
            <AppButton size='sm' color='white' onClick={handleBack} className='me-3'>
              <i className='fas fa-angle-left' />
            </AppButton>
          )}

          <div>
            {pathNames && <AppBreadcrumbs pathNames={pathNames} />}
            <span className='h4 text-semibold mb-0'>{title}</span>
            <div>{subtitle}</div>
          </div>
        </div>

        {actions && <div className='app-page-header-actions'>{actions}</div>}
      </div>

      {children}
    </div>
  );
};
export default AppPage;
