import {
  ApiAuthForgotPasswordPostRequest,
  ApiAuthLoginPostRequest,
  ApiAuthResetPasswordPostRequest,
  AuthApi,
  LoginRequestDTO,
  LoginResponseDTO,
  ResetPasswordRequestDTO,
  ResetPasswordResponseDTO,
  UserDTO,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';

enum AuthReducer {
  login = '[AUTH] Login',
  logout = '[AUTH] Logout',
  forgotPassword = '[AUTH] Forgot Password',
  resetPassword = '[AUTH] Reset Password',
  me = '[AUTH] Me',
}

export const authLogin = createAsyncThunk<
  LoginResponseDTO | undefined,
  LoginRequestDTO,
  ThunkApiConfig
>(AuthReducer.login, async (request, {}) => {
  try {
    let _request: ApiAuthLoginPostRequest = {
      loginRequestDTO: request,
    };

    return await new AuthApi(getConfiguration()).apiAuthLoginPost(_request);
  } catch (e) {
    errorHandler(e);
  }
});

export const authForgotPassword = createAsyncThunk<
  string | undefined,
  string,
  ThunkApiConfig
>(AuthReducer.forgotPassword, async (email, {}) => {
  try {
    let request: ApiAuthForgotPasswordPostRequest = {
      body: email,
    };

    return await new AuthApi(getConfiguration()).apiAuthForgotPasswordPost(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const authResetPassword = createAsyncThunk<
  ResetPasswordResponseDTO | undefined,
  ResetPasswordRequestDTO,
  ThunkApiConfig
>(AuthReducer.resetPassword, async (values, {}) => {
  try {
    let request: ApiAuthResetPasswordPostRequest = {
      resetPasswordRequestDTO: values,
    };

    return await new AuthApi(getConfiguration()).apiAuthResetPasswordPost(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const authMe = createAsyncThunk<UserDTO | undefined, void, ThunkApiConfig>(
  AuthReducer.me,
  async (_, {}) => {
    try {
      return await new AuthApi(getConfiguration()).apiAuthMePost();
    } catch (e) {
      errorHandler(e);
    }
  },
);

export const authLogout = createAction(AuthReducer.logout);
