import { AttivitaDTO } from '@api/client';
import { authUserSelector } from 'core/auth/_redux/selectors';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import ActivityCard from 'shared/activity-card';
import AppButton from 'shared/design-system/components/app-button';
import AppDropdown from 'shared/design-system/components/app-dropdown';
import AppNoResults from 'shared/design-system/components/app-no-results';
import AppPage from 'shared/design-system/components/app-page';
import AppPaginator from 'shared/design-system/components/app-paginator';
import AppSearchbox from 'shared/design-system/components/app-searchbox';
import { AppSearchboxActiveFilter } from 'shared/design-system/components/app-searchbox/types.models';
import {
  activitiesAddFilter,
  activitiesDeleteFilter,
  activitiesGetList,
  activitiesSetTablePage,
  activitiesSetTablePageSize,
  activitiesSetTableSort,
} from './_redux/actions';
import {
  activitiesFiltersSelector,
  activitiesListSelector,
  activitiesTablePageSelector,
  activitiesTablePageSizeSelector,
  activitiesTableSortDirSelector,
  activitiesTableSortSelector,
} from './_redux/selectors';
import ActivityEditModal from './activity-edit-modal';
import { searchBoxConfig } from './config';

interface Props {
  isLibrary?: boolean;
}

const ActivitiesPage: React.FC<Props> = props => {
  const { isLibrary } = props;

  const dispatch = useAppDispatch();

  const user = useAppSelector(authUserSelector);

  const tablePage = useAppSelector(activitiesTablePageSelector);
  const tablePageSize = useAppSelector(activitiesTablePageSizeSelector);
  const tableSort = useAppSelector(activitiesTableSortSelector);
  const tableSortDir = useAppSelector(activitiesTableSortDirSelector);
  const filters = useAppSelector(activitiesFiltersSelector);
  const response = useAppSelector(activitiesListSelector);

  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const handleTablePageChange = (page: number) => {
    dispatch(activitiesSetTablePage(page));
  };

  const handleTablePageSizeChange = (pageSize: number) => {
    dispatch(activitiesSetTablePageSize(pageSize));
  };

  const handleTableSort = (property: keyof AttivitaDTO) => {
    dispatch(activitiesSetTableSort(property));
  };

  const handleAddFilter = (filter: AppSearchboxActiveFilter) => {
    dispatch(activitiesAddFilter(filter));
  };

  const handleDeleteFilter = (filter: string) => {
    dispatch(activitiesDeleteFilter(filter));
  };

  const handleNewClick = () => {
    setShowEditForm(true);
  };

  const handleNew = (reload?: boolean) => {
    if (reload) getData();

    setShowEditForm(false);
  };

  const getData = () => {
    dispatch(activitiesGetList(!!isLibrary));
  };

  useEffect(() => {
    getData();
  }, [isLibrary, tablePage, tablePageSize, tableSort, tableSortDir, filters]);

  return (
    <>
      <AppPage
        title={!isLibrary ? 'Attività' : 'Libreria'}
        subtitle="Crea, modifica o elimina le attività da visualizzare all'interno dell'applicazione."
        actions={
          <AppButton onClick={() => handleNewClick()} className='w-full md:w-auto'>
            <i className='fas fa-plus me-2' />
            Aggiungi
          </AppButton>
        }
      >
        {!isLibrary && user?.isAdmin && (
          <div className='card'>
            <div className='card-body'>
              <div className='flex items-center'>
                <AppDropdown
                  name={<i className='fas fa-arrow-up-a-z text-base' />}
                  options={[
                    {
                      label: 'Nome',
                      onClick: () => handleTableSort('name'),
                      className: 'text-bold',
                    },
                  ]}
                  className='me-3'
                />

                <AppSearchbox
                  options={searchBoxConfig}
                  values={filters}
                  onAdd={handleAddFilter}
                  onDelete={handleDeleteFilter}
                />
              </div>
            </div>
          </div>
        )}

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 my-5'>
          {response?.items?.map((item, i) => (
            <ActivityCard
              key={`action_${i}`}
              activity={item}
              onEdit={() => getData()}
              onDelete={() => getData()}
              isLibrary={isLibrary}
            />
          ))}
        </div>

        {response?.items?.length == 0 ? (
          <AppNoResults
            {...(!user?.isAdmin
              ? {
                  customText: (
                    <>
                      Nessun risultato trovato <br />
                      Aggiungi la tua prima attività!
                    </>
                  ),
                }
              : {})}
          />
        ) : (
          <div className='card'>
            <div className='card-body'>
              <AppPaginator
                activePage={tablePage}
                pageSize={tablePageSize}
                elements={response?.count}
                onPageChange={handleTablePageChange}
                onPageSizeChange={handleTablePageSizeChange}
              />
            </div>
          </div>
        )}
      </AppPage>

      <ActivityEditModal show={showEditForm} onHide={handleNew} isLibrary={isLibrary} />
    </>
  );
};
export default ActivitiesPage;
