export enum PathEnum {
  AUTH = 'auth',
  LOGIN = 'login',
  COMPLETE_REGISTRATION = 'complete-registration',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',

  PRIVATE = 'private',
  HOME = 'home',

  ACTIVITIES = 'activities',
  LIBRARY = 'library',
  USER_MANAGER = 'user-manager',
}
