import { authMe } from 'core/auth/_redux/actions';
import AuthService from 'core/auth/auth.service';
import { PathEnum } from 'core/routes/path.enum';
import { useAppDispatch } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AuthGuard: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isAccesible, setIsAccessible] = useState<boolean>(true);

  useEffect(() => {
    if (AuthService.checkAuthentication()) {
      dispatch(authMe())
        .unwrap()
        .then(res => {
          if (res) {
            setIsAccessible(true);
          }
        });
    } else {
      setIsAccessible(false);
    }
  }, [AuthService.checkAuthentication()]);

  return isAccesible ? <Outlet /> : <Navigate to={`/${PathEnum.AUTH}`} replace />;
};
export default AuthGuard;
