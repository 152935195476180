import { useEffect, useState } from 'react';
import AppHeader from 'shared/design-system/components/app-header';
import AppMenuDrawer from 'shared/design-system/components/app-menu-drawer';
import PrivateRouter from './PrivateRouter';

const PrivatePage: React.FC = () => {
  var [headerHeight, setHeaderHeight] = useState<number>(0);

  useEffect(() => {
    setHeaderHeight(document.getElementById('header')?.clientHeight || 0);
  }, []);

  return (
    <div className='flex flex-col flex-1'>
      <AppMenuDrawer headerHeight={headerHeight}>
        <AppHeader />

        <div
          className='flex flex-row flex-1 justify-center'
          style={{ marginTop: headerHeight }}
        >
          <div className='page-container container p-4'>
            <PrivateRouter />
          </div>
        </div>
      </AppMenuDrawer>
    </div>
  );
};
export default PrivatePage;
