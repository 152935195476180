import { createReducer } from "@reduxjs/toolkit";
import { layoutSetPageTitle } from "./actions";

export interface LayoutReducerType {
	pageTitle: string | undefined;
}

const initialState: LayoutReducerType = {
	pageTitle: undefined,
};

export const layoutReducer = createReducer(initialState, (builder) => {
	builder.addCase(layoutSetPageTitle, (state, action) => {
		state.pageTitle = action.payload;
	});
});
