import { ReactNode } from 'react';

interface Props {
  options: {
    label: string;
    onClick: () => void;
    icon?: ReactNode;
  }[];
}

const AppMenuDropdown: React.FC<Props> = props => {
  const { options } = props;

  return (
    <div className='dropdown dropdown-end'>
      <div tabIndex={0} role='button' className={'btn btn-sm btn-ghost'}>
        <i className='fa-solid fa-ellipsis-vertical'></i>
      </div>

      <ul
        tabIndex={0}
        className='dropdown-content menu bg-base-content rounded-box z-[1] w-52 p-2 shadow'
      >
        {options?.map((item, i) => (
          <li key={`item_${i}`} onClick={() => item.onClick()} className='text-white'>
            <a>
              {item?.icon}

              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default AppMenuDropdown;
