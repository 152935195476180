// import { authLogout } from "core/auth/_redux/actions";
import { authLogout } from 'core/auth/_redux/actions';
import { authUserSelector } from 'core/auth/_redux/selectors';
import { SIDEBAR_ITEMS } from 'core/configs/sidebar.config';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './style.scss';

const AppHeader: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(authUserSelector);

  const [shortName, setShortName] = useState<string>('');

  const getItems = () => {
    if (!user?.isAdmin) return null;

    return SIDEBAR_ITEMS?.map((item, i) => (
      <li key={`nav-item_${i}`}>
        <NavLink to={item?.route}>{item.label}</NavLink>
      </li>
    ));
  };

  const handleLogout = () => {
    dispatch(authLogout());
  };

  useEffect(() => {
    setShortName(`${user?.firstName?.slice(0, 1)}${user?.lastName?.slice(0, 1)}`);
  }, [user]);

  return (
    <div
      className='navbar bg-base-100 shadow-md fixed top-0 z-10 app-header px-3'
      id='header'
    >
      <div className='navbar-start'>
        {user?.isAdmin && (
          <label
            htmlFor='sidebar-drawer'
            aria-label='open sidebar'
            className='btn btn-square btn-ghost swap swap-rotate me-2 md:hidden'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              className='inline-block w-6 h-6 stroke-current'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </label>
        )}

        <div className='normal-case text-md'>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} className='h-10' />
        </div>
      </div>

      <div className='navbar-center hidden md:block'>
        <ul className='menu menu-horizontal gap-3 px-1'>{getItems()}</ul>
      </div>

      <div className='navbar-end'>
        <div className='dropdown dropdown-end'>
          <label tabIndex={0} className='flex items-center flex-row' role='button'>
            <div className='avatar placeholder'>
              <div className='bg-primary text-neutral-50 rounded-full w-10'>
                <span className='text-sm text-semibold uppercase'>{shortName}</span>
              </div>
            </div>
            <i className='fas fa-angle-down text-xs ms-2 me-1' />
          </label>

          <ul
            tabIndex={0}
            className='menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52'
          >
            <div className='flex h-20 p-3'>
              <div className='avatar placeholder h-fit'>
                <div className='bg-primary text-neutral-50 rounded-full w-10'>
                  <span className='text-sm text-semibold uppercase'>{shortName}</span>
                </div>
              </div>

              <div className='ms-3'>
                <div className='section'>{user?.firstName}</div>
                <div className='section'>{user?.lastName}</div>
              </div>
            </div>

            <hr className='mb-2' />

            <li>
              <a onClick={() => handleLogout()}>
                <i className='fas fa-power-off' />
                <span className='text-medium'>Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default AppHeader;
