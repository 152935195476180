import { ReactNode } from 'react';
import './style.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'accent' | 'white' | 'danger' | 'light';
  variant?: 'solid' | 'outline';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  type?: 'button' | 'submit';
  disabled?: boolean;
  wide?: boolean;
  title?: string;
}

const AppButton: React.FC<Props> = (props: Props) => {
  const {
    className,
    color = 'primary',
    variant = 'solid',
    size = 'md',
    disabled,
    onClick,
    children,
    type = 'button',
    wide,
    title,
  } = props;

  const btnColors: { [key: string]: string } = {
    primary: 'btn-primary',
    secondary: 'btn-secondary',
    accent: 'btn-accent',
    white: 'btn-white',
    danger: 'btn-error',
    light: 'btn-light',
  };

  const btnVariants: { [key: string]: string } = {
    solid: '',
    outline: 'btn-outline',
  };

  const btnSize: { [key: string]: string } = {
    xs: 'btn-xs',
    sm: 'btn-sm',
    md: 'btn-md',
    lg: 'btn-lg',
  };

  return (
    <button
      onClick={onClick}
      className={`app-btn btn ${btnColors[color]} ${btnVariants[variant]} ${btnSize[size]} ${
        wide ? 'btn-wide' : ''
      } ${className || ''}`}
      disabled={disabled}
      type={type}
      title={title}
    >
      {children}
    </button>
  );
};
export default AppButton;
