import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

const AppAccordion: React.FC<Props> = props => {
  const { title, children } = props;

  return (
    <details className='collapse collapse-arrow bg-base-100'>
      <summary className='collapse-title text-xl font-medium'>{title}</summary>

      <div className='collapse-content'>{children}</div>
    </details>
  );
};
export default AppAccordion;
