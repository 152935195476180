import { ResetPasswordRequestDTO } from '@api/client';
import { authResetPassword } from 'core/auth/_redux/actions';
import { AppRouterService } from 'core/routes/app-router.service';
import { PathEnum } from 'core/routes/path.enum';
import { useAppDispatch } from 'core/store/hooks';
import { Formik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppButton from 'shared/design-system/components/app-button';
import AppInput from 'shared/design-system/components/app-input';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import * as Yup from 'yup';

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  const userId = searchParams.get('u');
  const token = searchParams.get('t');

  const handleSubmit = (values: FormValues) => {
    dispatch(authResetPassword(values))
      .unwrap()
      .then(res => {
        if (res) {
          AppToastService.success('Password resettata con successo!');

          navigate(AppRouterService.get(PathEnum.HOME));
        }
      });
  };

  return (
    <Formik
      initialValues={{
        userId: Number(userId),
        token: token!,
        password: '',
        confirm: '',
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().passwordValidation().required(),
        confirm: Yup.string()
          .oneOf([Yup.ref('password'), undefined], 'Le password non corrispondono')
          .required(),
      })}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleSubmit: _handleSubmit,
        handleBlur,
        handleChange,
      }) => {
        return (
          <form onSubmit={_handleSubmit}>
            <h4 className='text-gray-900 text-semibold mb-3'>Reset Password</h4>
            <div className='caption mb-5'>Digita la tua nuova password.</div>

            <AppInput
              type='password'
              name='password'
              label='Password'
              value={values.password!}
              onChange={handleChange}
              onBlur={handleBlur}
              className='mb-3'
              isInvalid={!!errors.password && touched.password}
              error={errors.password}
            />

            <AppInput
              type='password'
              name='confirm'
              label='Conferma Password'
              value={values.confirm}
              onChange={handleChange}
              onBlur={handleBlur}
              className='mb-3'
              isInvalid={!!errors.confirm && touched.confirm}
              error={errors.confirm}
            />

            <AppButton type='submit'>Resetta</AppButton>
          </form>
        );
      }}
    </Formik>
  );
};
export default ResetPasswordPage;

interface FormValues extends ResetPasswordRequestDTO {
  confirm: string;
}
