import { JwtToken } from './token.model';

const TOKEN_KEY = 'token';

namespace AuthService {
  export const getToken = (): JwtToken => {
    let token = sessionStorage.getItem(TOKEN_KEY);

    return token ? JSON.parse(token) : undefined;
  };

  export const decodeToken = () => {
    let token = getToken();
  };

  export const checkAuthentication = () => {
    let token = getToken();

    return !!token;
  };

  export const setAuthentication = (token: string) => {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  };

  export const logout = () => {
    console.log({ location: window.location });
    sessionStorage.removeItem(TOKEN_KEY);
    window.location.reload();
    window.location.pathname = '/';
  };

  export const isAuthorized = () => {
    return true;
  };
}
export default AuthService;
