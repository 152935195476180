import { appConfig } from "core/configs/app.config";
import * as Yup from "yup";

Yup.setLocale({
	string: {
		email: "Email non valida",
	},
	mixed: {
		required: "Campo richiesto",
		notType: "Campo non valido",
	},
});

Yup.addMethod(Yup.string, "passwordValidation", function () {
	return this.test(
		"passwordValidation",
		"La password deve contenere almeno 8 caratteri, un numero, una maiuscola, una minuscola e un carattere speciale (#$^+=!*()@%&)",
		function (value) {
			return passwordValidation(value);
		}
	);
});

declare module "yup" {
	interface StringSchema {
		passwordValidation(): StringSchema<string>;
	}
}

const passwordValidation = (value: string | undefined) => {
	const reg = appConfig.passwordRegex;

	if (!value) return true;

	return reg.test(value);
};

export default Yup;
