import { AppSearchboxOptions } from 'shared/design-system/components/app-searchbox/types.models';

export const searchBoxConfig: AppSearchboxOptions[] = [
  {
    label: 'Nome',
    key: 'firstName',
  },
  {
    label: 'Cognome',
    key: 'lastName',
  },
  {
    label: 'Email',
    key: 'email',
  },
];
