interface Props {
  pathNames?: string[];
}

const AppBreadcrumbs: React.FC<Props> = props => {
  const { pathNames } = props;

  return (
    <div className='breadcrumbs text-sm'>
      <ul>
        {pathNames?.map((item, i) => (
          <li key={`breadcrumbs_item_${i}`}>{item}</li>
        ))}
      </ul>
    </div>
  );
};
export default AppBreadcrumbs;
