import {
  ApiAttivitaEntIdDeleteRequest,
  ApiAttivitaEntIdGetRequest,
  ApiAttivitaEntIdPutRequest,
  ApiAttivitaGetRequest,
  ApiAttivitaPostRequest,
  AttivitaApi,
  AttivitaCreateRequest,
  AttivitaDTO,
  AttivitaDTOListBaseResponse,
} from '@api/client';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApiConfig } from 'core/store';
import getConfiguration, { errorHandler } from 'core/store/config';
import { AppSearchboxActiveFilter } from 'shared/design-system/components/app-searchbox/types.models';

enum ActivitiesReducer {
  setTablePage = '[ACTIVITIES] Set table page',
  setTablePageSize = '[ACTIVITIES] Set table page size',
  setTableSort = '[ACTIVITIES] Set table sort',
  addFilter = '[ACTIVITIES] Add filter',
  deleteFilter = '[ACTIVITIES] Delete filter',
  getList = '[ACTIVITIES] Get',
  getDetails = '[ACTIVITIES] Get Details',
  create = '[ACTIVITIES] Create',
  edit = '[ACTIVITIES] Edit',
}

export const activitiesSetTablePage = createAction<number>(
  ActivitiesReducer.setTablePage,
);

export const activitiesSetTablePageSize = createAction<number>(
  ActivitiesReducer.setTablePageSize,
);

export const activitiesSetTableSort = createAction<keyof AttivitaDTO>(
  ActivitiesReducer.setTableSort,
);

export const activitiesAddFilter = createAction<AppSearchboxActiveFilter>(
  ActivitiesReducer.addFilter,
);

export const activitiesDeleteFilter = createAction<string>(
  ActivitiesReducer.deleteFilter,
);

export const activitiesGetList = createAsyncThunk<
  AttivitaDTOListBaseResponse | undefined,
  boolean,
  ThunkApiConfig
>(ActivitiesReducer.getList, async (isLibrary, { getState }) => {
  try {
    let activitiesState = getState()?.private.activities;

    let request: ApiAttivitaGetRequest = {
      page: activitiesState.page,
      pageSize: activitiesState.pageSize,
      orderBy: activitiesState.orderBy,
      orderByDir: activitiesState.orderByDir,
      isLibrary,
    };

    let filters: { [key in keyof ApiAttivitaGetRequest]: any } = {};

    activitiesState?.filters?.forEach(f => {
      filters[f.key as keyof ApiAttivitaGetRequest] = f.value;
    });

    request = { ...request, ...filters };

    return await new AttivitaApi(getConfiguration()).apiAttivitaGet(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const activitiesGetDetails = createAsyncThunk<
  AttivitaDTO | undefined,
  number,
  ThunkApiConfig
>(ActivitiesReducer.getDetails, async (id, {}) => {
  try {
    let request: ApiAttivitaEntIdGetRequest = {
      entId: id,
    };

    return await new AttivitaApi(getConfiguration()).apiAttivitaEntIdGet(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const activitiesCreate = createAsyncThunk<
  AttivitaDTO | undefined,
  AttivitaCreateRequest,
  ThunkApiConfig
>(ActivitiesReducer.create, async (values, {}) => {
  try {
    let request: ApiAttivitaPostRequest = {
      attivitaCreateRequest: values,
    };

    return await new AttivitaApi(getConfiguration()).apiAttivitaPost(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const activitiesEdit = createAsyncThunk<
  AttivitaDTO | undefined,
  { id: number; values: AttivitaCreateRequest },
  ThunkApiConfig
>(ActivitiesReducer.edit, async (req, {}) => {
  try {
    let request: ApiAttivitaEntIdPutRequest = {
      entId: req.id,
      attivitaCreateRequest: req.values,
    };

    return await new AttivitaApi(getConfiguration()).apiAttivitaEntIdPut(request);
  } catch (e) {
    errorHandler(e);
  }
});

export const activitiesDelete = createAsyncThunk<
  void | undefined,
  number,
  ThunkApiConfig
>(ActivitiesReducer.edit, async (entId, {}) => {
  try {
    let request: ApiAttivitaEntIdDeleteRequest = {
      entId,
    };

    return await new AttivitaApi(getConfiguration()).apiAttivitaEntIdDelete(request);
  } catch (e) {
    errorHandler(e);
  }
});
