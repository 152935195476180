import { authUserSelector } from 'core/auth/_redux/selectors';
import { SIDEBAR_ITEMS } from 'core/configs/sidebar.config';
import { useAppSelector } from 'core/store/hooks';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface Props {
  children: ReactNode;
  headerHeight: number;
}

const AppMenuDrawer: React.FC<Props> = props => {
  const { children, headerHeight } = props;

  const user = useAppSelector(authUserSelector);

  const getItems = () => {
    if (!user?.isAdmin) return null;

    return SIDEBAR_ITEMS?.map((item, i) => (
      <li key={`nav-item_${i}`}>
        <NavLink to={item?.route} onClick={handleClick}>
          {item.label}
        </NavLink>
      </li>
    ));
  };

  const handleClick = () => {
    setTimeout(() => {
      document?.getElementById('sidebar-drawer')?.click();
    }, 150);
  };

  return (
    <div className='drawer'>
      <input id='sidebar-drawer' type='checkbox' className='drawer-toggle' />

      <div className='drawer-content flex flex-col'>{children}</div>

      <div className='drawer-side' style={{ paddingTop: headerHeight }}>
        <label
          htmlFor='sidebar-drawer'
          aria-label='close sidebar'
          className='drawer-overlay'
        />

        <ul className='menu bg-base-200 min-h-full w-80 p-4'>{getItems()}</ul>
      </div>
    </div>
  );
};
export default AppMenuDrawer;
