import { PathEnum } from 'core/routes/path.enum';
import { Navigate, Route, Routes } from 'react-router-dom';
import CompleteRegistrationPage from './complete-registration';
import ForgotPasswordPage from './forgot-password';
import LoginPage from './login';
import ResetPasswordPage from './reset-password';

const AuthRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={''} element={<Navigate to={PathEnum.LOGIN} />} />
      <Route path={'*'} element={<Navigate to={PathEnum.LOGIN} />} />

      <Route path={PathEnum.LOGIN} element={<LoginPage />} />

      <Route
        path={PathEnum.COMPLETE_REGISTRATION}
        element={<CompleteRegistrationPage />}
      />

      <Route path={PathEnum.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />

      <Route path={PathEnum.RESET_PASSWORD} element={<ResetPasswordPage />} />
    </Routes>
  );
};
export default AuthRouter;
