import { ChangeEvent, ReactNode } from 'react';

interface Props {
  show: boolean;
  onClose: () => void;

  title?: string;
  children?: ReactNode;
  className?: string;
  block?: boolean;
}

const AppModal: React.FC<Props> = props => {
  const { show, onClose, title, children, className, block } = props;

  const handleClose = () => {
    onClose();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {};

  return (
    <>
      <input
        type='checkbox'
        id='app-modal'
        className='modal-toggle'
        checked={show}
        onChange={handleChange}
      />

      <div className={'modal ' + className}>
        <div className='modal-box w-11/12 md:w-8/12 max-w-3xl'>
          <div className='mb-5'>
            {title && <h3 className='font-bold text-lg'>{title}</h3>}

            <button
              className='btn btn-sm btn-circle btn-ghost absolute right-2 top-2'
              onClick={() => handleClose()}
            >
              ✕
            </button>
          </div>

          <div className=''>{children}</div>
        </div>

        {!block && (
          <label
            className='modal-backdrop'
            htmlFor='app-modal'
            onClick={() => handleClose()}
          >
            Close
          </label>
        )}
      </div>
    </>
  );
};
export default AppModal;
