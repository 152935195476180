import { ReactComponent as SearchIcon } from './assets/svg/search-icon.svg';

import { Formik, FormikHelpers } from 'formik';
import Yup from 'shared/utility/validation.utility';
import AppChip from '../app-chip';
import { AppSearchboxActiveFilter, AppSearchboxOptions } from './types.models';

interface Props {
  options: AppSearchboxOptions[];
  values: AppSearchboxActiveFilter[] | undefined;
  onAdd: (value: AppSearchboxActiveFilter) => void;
  onDelete: (filter: string) => void;
}

const AppSearchbox: React.FC<Props> = props => {
  const { options, values = [], onAdd, onDelete } = props;

  const _handleSubmit = (
    values: { property: string; value: string },
    opts: FormikHelpers<{ property: string; value: string }>,
  ) => {
    let filter: AppSearchboxActiveFilter = {
      key: values.property,
      label: options.find(o => o.key == values.property)?.label!,
      value: values.value,
    };

    onAdd(filter);
    opts?.resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          property: '',
          value: '',
        }}
        validationSchema={Yup.object().shape({
          property: Yup.string().required('Seleziona la proprietà da filtrare'),
          value: Yup.string().required('Inserisci il valore'),
        })}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={_handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => {
          return (
            <>
              <form className='join w-full' onSubmit={handleSubmit} autoComplete='off'>
                <select
                  className={`select focus:outline-none bg-gray-200 join-item ${
                    !!errors?.property && touched?.property ? 'input-error' : ''
                  }`}
                  name='property'
                  value={values?.property}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={''} disabled>
                    Cerca
                  </option>

                  {options?.map((o, i) => (
                    <option key={`opt_${i}`} value={o.key}>
                      {o?.label}
                    </option>
                  ))}
                </select>

                <input
                  className={`input input-bordered focus:outline-none w-full join-item flex-1 ${
                    !!errors?.value && touched?.value ? 'input-error' : ''
                  }`}
                  name='value'
                  value={values?.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <button className='btn btn-primary px-3 join-item' type='submit'>
                  <SearchIcon className='fill-primary-content' />
                </button>
              </form>

              {errors && (
                <label className='label'>
                  <span className='label-text-alt text-error text-semibold'>
                    {errors?.property || errors?.value}
                  </span>
                </label>
              )}
            </>
          );
        }}
      </Formik>

      {values && values?.length > 0 && (
        <div className='flex items-center gap-2 mt-2'>
          <span className='text-sm font-bold me-2'>Filtri attivi:</span>
          {values?.map((v, i) => (
            <AppChip
              key={`active-filter_${i}`}
              onDelete={() => onDelete(v?.key)}
              deletable
            >
              <span className='font-bold me-1'>{v?.label}:</span>
              <span>{v?.value}</span>
            </AppChip>
          ))}
        </div>
      )}
    </>
  );
};
export default AppSearchbox;
