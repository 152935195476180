import { ReactNode } from 'react';
import './style.scss';

interface Props {
  title: string;
  value: string | number | ReactNode | null | undefined;
  className?: string;
  size?: 'normal' | 'sm';
}

const AppSpanValue: React.FC<Props> = props => {
  const { value, title, className, size = 'normal' } = props;

  const sizes = {
    normal: ['text-sm', 'text-base'],
    sm: ['text-xs', 'text-sm'],
  };

  return (
    <div className={'span-values ' + className}>
      <div className={sizes[size][0]}>{title}</div>
      <div className={sizes[size][1] + ' font-bold'}>{value || '-'}</div>
    </div>
  );
};
export default AppSpanValue;
