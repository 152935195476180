import { ReactNode } from 'react';

interface Props {
  name: ReactNode;
  options: {
    label: string;
    onClick: () => void;
    icon?: ReactNode;
    className?: string;
  }[];
  className?: string;
}

const AppDropdown: React.FC<Props> = props => {
  const { name, options, className } = props;

  return (
    <div className='dropdown'>
      <div tabIndex={0} role='button' className={'btn btn-neutral ' + className || ''}>
        {name}
      </div>

      <ul
        tabIndex={0}
        className='dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow'
      >
        {options?.map((item, i) => (
          <li key={`item_${i}`} onClick={() => item.onClick()} className={item?.className || ''}>
            <a>
              {item?.icon}

              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default AppDropdown;
