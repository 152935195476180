const useTextToSpeech = () => {
  const synth = window.speechSynthesis;

  const play = (text: string) => {
    const utterThis = new SpeechSynthesisUtterance(text);

    synth.speak(utterThis);
  };

  return { play };
};
export default useTextToSpeech;
