import { PathEnum } from './path.enum';

export namespace AppRouterService {
  const routes: { [key: string]: string } = {
    [PathEnum.AUTH]: `/${PathEnum.AUTH}`,
    [PathEnum.LOGIN]: `/${PathEnum.AUTH}/${PathEnum.LOGIN}`,

    [PathEnum.PRIVATE]: `/${PathEnum.PRIVATE}`,
    [PathEnum.HOME]: `/${PathEnum.PRIVATE}/${PathEnum.HOME}`,

    [PathEnum.ACTIVITIES]: `/${PathEnum.PRIVATE}/${PathEnum.ACTIVITIES}`,
    [PathEnum.USER_MANAGER]: `/${PathEnum.PRIVATE}/${PathEnum.USER_MANAGER}`,
  };

  export const get = (key: PathEnum) => {
    let route = routes[key];

    if (!route) throw 'No route found: ' + key;

    return route;
  };
}
