import { UserDTO } from '@api/client';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppButton from 'shared/design-system/components/app-button';
import AppNoResults from 'shared/design-system/components/app-no-results';
import AppPage from 'shared/design-system/components/app-page';
import AppPaginator from 'shared/design-system/components/app-paginator';
import AppSearchbox from 'shared/design-system/components/app-searchbox';
import { AppSearchboxActiveFilter } from 'shared/design-system/components/app-searchbox/types.models';
import AppTable from 'shared/design-system/components/app-table';
import AppTableTh from 'shared/design-system/components/app-table/app-table-th';
import {
  userManagerAddFilter,
  userManagerDeleteFilter,
  userManagerGetUsers,
  userManagerSetTablePage,
  userManagerSetTablePageSize,
  userManagerSetTableSort,
} from './_redux/actions';
import {
  userManagerFiltersSelector,
  userManagerTablePageSelector,
  userManagerTablePageSizeSelector,
  userManagerTableSortDirSelector,
  userManagerTableSortSelector,
  userManagerUsersSelector,
} from './_redux/selectors';
import { searchBoxConfig } from './config';
import UserManagerEditModal from './user-manager-edit-modal';

const UserManagerPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tablePage = useAppSelector(userManagerTablePageSelector);
  const tablePageSize = useAppSelector(userManagerTablePageSizeSelector);
  const tableSort = useAppSelector(userManagerTableSortSelector);
  const tableSortDir = useAppSelector(userManagerTableSortDirSelector);
  const filters = useAppSelector(userManagerFiltersSelector);
  const response = useAppSelector(userManagerUsersSelector);

  const [showEditForm, setShowEditForm] = useState<number>();

  const handleNew = () => {
    setShowEditForm(0);
  };

  const handleEdit = (id: number | undefined) => {
    if (id) setShowEditForm(id);
  };

  const handleTablePageChange = (page: number) => {
    dispatch(userManagerSetTablePage(page));
  };

  const handleTablePageSizeChange = (pageSize: number) => {
    dispatch(userManagerSetTablePageSize(pageSize));
  };

  const handleTableSort = (property: keyof UserDTO) => {
    dispatch(userManagerSetTableSort(property));
  };

  const handleAddFilter = (filter: AppSearchboxActiveFilter) => {
    dispatch(userManagerAddFilter(filter));
  };

  const handleDeleteFilter = (filter: string) => {
    dispatch(userManagerDeleteFilter(filter));
  };

  const getData = () => {
    dispatch(userManagerGetUsers());
  };

  useEffect(() => {
    getData();
  }, [tablePage, tablePageSize, tableSort, tableSortDir, filters]);

  return (
    <AppPage
      title='User Manager'
      actions={
        <AppButton onClick={() => handleNew()}>
          <i className='fas fa-plus me-2' />
          Aggiungi
        </AppButton>
      }
    >
      <div className='card'>
        <div className='card-body'>
          <div className='mb-3'>
            <AppSearchbox
              options={searchBoxConfig}
              values={filters}
              onAdd={handleAddFilter}
              onDelete={handleDeleteFilter}
            />
          </div>

          <AppTable>
            <thead>
              <tr>
                <AppTableTh
                  onSort={() => handleTableSort('email')}
                  property='email'
                  sort={tableSort}
                  sortDir={tableSortDir}
                >
                  User
                </AppTableTh>

                <AppTableTh
                  onSort={() => handleTableSort('isAdmin')}
                  property='isAdmin'
                  sort={tableSort}
                  sortDir={tableSortDir}
                >
                  Ruolo
                </AppTableTh>

                <AppTableTh className='text-end'>&nbsp;</AppTableTh>
              </tr>
            </thead>

            <tbody>
              {response?.items?.map((r, i) => (
                <tr key={`user-manager-table-row_${i}`}>
                  <td>
                    <span>
                      <div className='text-base text-bold'>
                        {r?.firstName} {r?.lastName}
                      </div>
                      <div>{r?.email}</div>
                    </span>
                  </td>
                  <td>
                    <span className='section'>{r?.isAdmin ? 'Admin' : 'User'}</span>
                  </td>
                  <td align='right'>
                    <AppButton color='light' onClick={() => handleEdit(r.id)} size='sm'>
                      Modifica
                    </AppButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </AppTable>

          {response?.items && response?.items?.length == 0 ? (
            <AppNoResults />
          ) : (
            <AppPaginator
              activePage={tablePage}
              pageSize={tablePageSize}
              elements={response?.count}
              onPageChange={handleTablePageChange}
              onPageSizeChange={handleTablePageSizeChange}
            />
          )}
        </div>
      </div>

      <UserManagerEditModal
        show={showEditForm != undefined}
        userId={showEditForm}
        onHide={e => {
          if (e) getData();
          setShowEditForm(undefined);
        }}
      />
    </AppPage>
  );
};
export default UserManagerPage;
