import AppAccordion from '../components/app-accordion';
import AppButton from '../components/app-button';
import AppInput from '../components/app-input';
import AppPage from '../components/app-page';
import AppSelect from '../components/app-select';

const KitchenSink: React.FC = () => {
  const typography = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'a'];

  const style = getComputedStyle(document.body);
  const colors = {
    main: ['--primary', '--secondary', '--accent', '--success', '--warning', '--danger'],
    // bg: [
    //   '--bg-primary',
    //   '--bg-secondary',
    //   '--bg-overlay',
    //   '--bg-info',
    //   '--bg-success',
    //   '--bg-warning',
    //   '--bg-danger',
    //   '--bg-disabled',
    // ],
    // text: [
    //   '--text-primary',
    //   '--text-secondary',
    //   '--text-success',
    //   '--text-warning',
    //   '--text-danger',
    //   '--text-disabled',
    // ],
  };

  return (
    <div className='p-5'>
      <AppPage title='Kitchen Sink'>
        <div className='flex flex-col gap-3'>
          <AppAccordion title='Typography'>
            <div className='flex flex-col gap-5'>
              {typography?.map(t => (
                <div key={t}>
                  <div className={t}>{t}</div>
                  {t == 'a' ? (
                    <a href='#'>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    </a>
                  ) : (
                    <div className={t}>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    </div>
                  )}
                </div>
              ))}
            </div>

            <hr />

            <h3>Weight</h3>
            {[300, 400, 500, 600, 700, 800]?.map(t => (
              <div key={t} style={{ fontWeight: t }}>
                {t} - Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </div>
            ))}
          </AppAccordion>

          <AppAccordion title='Colors'>
            <div className='flex gap-4'>
              {colors.main.map(c => (
                <div
                  key={c}
                  className='p-2 shadow'
                  style={{
                    minWidth: 180,
                    height: 200,
                    background: style.getPropertyValue(c),
                  }}
                >
                  <div
                    className='w-100 px-3 py-2'
                    style={{ marginBottom: 0, marginTop: 'auto' }}
                  >
                    <b>{c.replace('--', '')}</b>
                    <div>{style.getPropertyValue(c)}</div>
                  </div>
                </div>
              ))}
            </div>

            {/* <h5 className='fw-bold w-100 mt-5 mb-3 border-bottom'>Background</h5>
            <div className='flex gap-4'>
              {colors.bg.map(c => (
                <div
                  key={c}
                  className='p-2 shadow'
                  style={{
                    minWidth: 180,
                    height: 200,
                    background: style.getPropertyValue(c),
                  }}
                >
                  <div
                    className='w-100 px-3 py-2'
                    style={{ marginBottom: 0, marginTop: 'auto' }}
                  >
                    <b>{c.replace('--', '')}</b>
                    <div>{style.getPropertyValue(c)}</div>
                  </div>
                </div>
              ))}
            </div>

            <h5 className='fw-bold w-100 mt-5 mb-3 border-bottom'>Text</h5>
            <div className='flex gap-4'>
              {colors.text.map(c => (
                <div
                  key={c}
                  className='p-2 shadow'
                  style={{
                    minWidth: 180,
                    height: 200,
                    background: style.getPropertyValue(c),
                  }}
                >
                  <div
                    className='w-100 px-3 py-2'
                    style={{ marginBottom: 0, marginTop: 'auto' }}
                  >
                    <b>{c.replace('--', '')}</b>
                    <div>{style.getPropertyValue(c)}</div>
                  </div>
                </div>
              ))}
            </div> */}
          </AppAccordion>

          <AppAccordion title='Form Components'>
            {['solid', 'outline', 'ghost']?.map(b => (
              <div className='flex gap-3 mb-3' key={b}>
                <AppButton>{b}</AppButton>
                <AppButton disabled>{b} disabled</AppButton>
              </div>
            ))}
            <hr />

            <div className='flex flex-column gap-3'>
              <AppInput
                label={'Input'}
                value={''}
                onChange={console.log}
                placeholder='Placeholder'
              />
              <AppInput
                label={'Input'}
                value={''}
                onChange={console.log}
                placeholder='Placeholder'
              />
              <AppInput label={'Input'} value={'Value'} onChange={console.log} />
              <AppInput
                label={'Input error'}
                value={''}
                onChange={console.log}
                placeholder='Placeholder'
                isInvalid
                error='Error message'
              />
              <AppInput
                label={'Input disabled'}
                value={''}
                onChange={console.log}
                placeholder='Placeholder'
                disabled
              />
              <AppInput
                label={'Input readonly'}
                value={''}
                onChange={console.log}
                placeholder='Placeholder'
                readOnly
              />
            </div>

            <hr />

            <div className='flex flex-column gap-3'>
              <AppSelect label='Select' value={'Option 2'} onChange={console.log}>
                <option value='Option 1'>Option 1</option>
                <option value='Option 2'>Option 2</option>
                <option value='Option 3'>Option 3</option>
              </AppSelect>

              <AppSelect label='Select' value={'Option 2'} onChange={console.log}>
                <option value='Option 1'>Option 1</option>
                <option value='Option 2'>Option 2</option>
                <option value='Option 3'>Option 3</option>
              </AppSelect>

              <AppSelect
                label='Select'
                value={'Option 2'}
                onChange={console.log}
                isInvalid
                error='Error message'
              >
                <option value='Option 1'>Option 1</option>
                <option value='Option 2'>Option 2</option>
                <option value='Option 3'>Option 3</option>
              </AppSelect>

              <AppSelect
                label='Select disabled'
                value={'Option 2'}
                onChange={console.log}
                disabled
              >
                <option value='Option 1'>Option 1</option>
                <option value='Option 2'>Option 2</option>
                <option value='Option 3'>Option 3</option>
              </AppSelect>
            </div>
          </AppAccordion>
        </div>
      </AppPage>
    </div>
  );
};
export default KitchenSink;
