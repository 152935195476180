import toast from 'react-hot-toast';

export namespace AppToastService {
  export const success = (message: string) => {
    toast.success(message);
  };

  export const error = (message: string) => {
    toast.error(message);
  };
}
