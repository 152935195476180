import { ReactNode } from 'react';
import AppButton from '../app-button';
import AppModal from '../app-modal';
import './style.scss';

interface Props {
  show: boolean;
  message: string | ReactNode;
  onConfirm: () => void;

  title?: string;
  onHide?: () => void;
}

const AppConfirm: React.FC<Props> = props => {
  const { show, message, title, onConfirm, onHide } = props;

  return (
    <AppModal show={show} onClose={() => onHide?.()} className='app-confirm' title={title}>
      <div className='mb-5'>{message}</div>

      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div>
          <AppButton color='white' onClick={() => onHide?.()}>
            Annulla
          </AppButton>
        </div>
        <div className='text-end'>
          <AppButton color='primary' onClick={() => onConfirm()}>
            Conferma
          </AppButton>
        </div>
      </div>
    </AppModal>
  );
};
export default AppConfirm;
