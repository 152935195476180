import AppRouter from 'AppRouter';
import { store } from 'core/store';
import { Provider } from 'react-redux';
import AppLoader from 'shared/design-system/components/app-loader';
import AppToast from 'shared/design-system/components/app-toast';
import 'shared/extensions/types.extension';
import './App.scss';

function App() {
  return (
    <Provider store={store}>
      <AppRouter />

      <AppLoader />
      <AppToast />
    </Provider>
  );
}

export default App;
