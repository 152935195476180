import { RegisterRequestDTO, UserCreateDTO, UserDTO } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import AppButton from 'shared/design-system/components/app-button';
import AppConfirm from 'shared/design-system/components/app-confirm';
import AppInput from 'shared/design-system/components/app-input';
import AppModal from 'shared/design-system/components/app-modal';
import AppSelect from 'shared/design-system/components/app-select';
import * as Yup from 'yup';
import {
  userManagerCreateUser,
  userManagerDeleteUser,
  userManagerEditUser,
  userManagerGetUserDetails,
} from '../_redux/actions';

interface Props {
  show: boolean;
  onHide: (reload?: boolean) => void;

  userId?: number | undefined;
}

const UserManagerEditModal: React.FC<Props> = props => {
  const { show, userId, onHide } = props;

  const dispatch = useAppDispatch();

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [data, setData] = useState<UserDTO | undefined>();

  const handleHide = (reload?: boolean) => {
    setData(undefined);
    onHide(reload);
  };

  const toggleDeleteClick = (_show: boolean) => {
    setShowConfirmDelete(_show);
  };

  const handleDelete = () => {
    if (userId == undefined) throw 'UserId not found';

    dispatch(userManagerDeleteUser(userId))
      .unwrap()
      .then(res => {
        toggleDeleteClick(false);
        handleHide(true);
      });
  };

  const getDetails = () => {
    if (!userId) return;

    dispatch(userManagerGetUserDetails(userId))
      .unwrap()
      .then(res => setData(res));
  };

  const handleSubmit = (values: FormValues) => {
    let request;

    if (userId) {
      let _request: UserCreateDTO = {
        ...data,
        ...values,
        isAdmin: values.isAdmin == 'true',
      };

      request = dispatch(userManagerEditUser({ id: userId, request: _request }));
    } else {
      let _request: RegisterRequestDTO = {
        ...values,
        isAdmin: values.isAdmin == 'true',
      };

      request = dispatch(userManagerCreateUser(_request));
    }

    request.unwrap().then(res => {
      handleHide(true);
    });
  };

  useEffect(() => {
    getDetails();
  }, [show]);

  if (!show || (userId && !data)) return null;

  return (
    <AppModal
      show={show}
      onClose={() => handleHide()}
      title={`${userId ? 'Modifica' : 'Nuovo'} User`}
    >
      <Formik<FormValues>
        initialValues={{
          firstName: data?.firstName || '',
          lastName: data?.lastName || '',
          email: data?.email || '',
          isAdmin: data?.isAdmin ? 'true' : 'false',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required(),
          lastName: Yup.string().required(),
          email: Yup.string().email().required(),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleSubmit: _handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          return (
            <form
              onSubmit={_handleSubmit}
              className='grid grid-cols-1 gap-3'
              autoComplete='off'
            >
              <AppInput
                label='Nome'
                name='firstName'
                value={values.firstName!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.firstName && touched?.firstName}
                error={errors?.firstName}
                autoFocus
              />

              <AppInput
                label='Cognome'
                name='lastName'
                value={values.lastName!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.lastName && touched?.lastName}
                error={errors?.lastName}
              />

              <AppInput
                label='Email'
                name='email'
                value={values.email!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.email && touched?.email}
                error={errors?.email}
              />

              <AppSelect
                label='È un amministratore di sistema?'
                name='isAdmin'
                value={values.isAdmin!}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors?.isAdmin && touched?.isAdmin}
                error={errors?.isAdmin}
              >
                <option value={'false'}>No</option>
                <option value={'true'}>Si</option>
              </AppSelect>

              {userId != 0 && (
                <>
                  <div className='divider' />

                  <AppButton color='danger' onClick={() => toggleDeleteClick(true)}>
                    Elimina Utente
                  </AppButton>
                </>
              )}

              <div className='grid grid-cols-1 md:grid-cols-2 form-actions'>
                <div className='order-2 md:order-1'>
                  <AppButton
                    color='white'
                    onClick={() => handleHide()}
                    className='w-full md:w-auto'
                  >
                    Annulla
                  </AppButton>
                </div>
                <div className='text-end order-1 md:order-2'>
                  <AppButton type='submit' className='w-full md:w-auto'>
                    Salva
                  </AppButton>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>

      <AppConfirm
        title='Cancella utente'
        message="Confermi di voler cancellare l'utente?"
        show={showConfirmDelete}
        onHide={() => toggleDeleteClick(false)}
        onConfirm={() => handleDelete()}
      />
    </AppModal>
  );
};
export default UserManagerEditModal;

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  isAdmin: 'true' | 'false';
}
