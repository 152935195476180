import { UserDTO, UserDTOListBaseResponse } from "@api/client";
import { createReducer } from "@reduxjs/toolkit";
import { AppSearchboxActiveFilter } from "shared/design-system/components/app-searchbox/types.models";
import { BaseSortDir } from "shared/design-system/models/base.model";
import {
	userManagerAddFilter,
	userManagerDeleteFilter,
	userManagerGetUsers,
	userManagerSetTablePage,
	userManagerSetTablePageSize,
	userManagerSetTableSort,
} from "./actions";

interface InitialValues {
	page: number;
	pageSize: number;
	orderBy: keyof UserDTO | undefined;
	orderByDir: BaseSortDir | undefined;
	filters: AppSearchboxActiveFilter[];
	usersResponse: UserDTOListBaseResponse | undefined;
}

const initialState: InitialValues = {
	page: 1,
	pageSize: 10,
	orderBy: "email",
	orderByDir: "asc",
	filters: [],
	usersResponse: undefined,
};

export const userManagerReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(userManagerSetTablePage, (state, action) => {
			state.page = action.payload;
		})
		.addCase(userManagerSetTablePageSize, (state, action) => {
			state.page = 1;
			state.pageSize = action.payload;
		})
		.addCase(userManagerAddFilter, (state, action) => {
			state.filters.push(action.payload);
		})
		.addCase(userManagerDeleteFilter, (state, action) => {
			state.filters = state.filters.filter((f) => f.key != action.payload);
		})
		.addCase(userManagerSetTableSort, (state, action) => {
			state.orderByDir =
				state.orderBy == action?.payload
					? state.orderByDir == "asc"
						? "desc"
						: "asc"
					: state.orderByDir;
			state.orderBy = action?.payload;
		})
		.addCase(userManagerGetUsers.fulfilled, (state, action) => {
			state.usersResponse = action.payload;
		});
});
