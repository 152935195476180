// import { ErrorResponseDto } from "@api/client";
import { createAction } from "@reduxjs/toolkit";

const enum GeneralReducer {
	setGeneralError = "[GENERAL] Set general error",
	resetGeneralError = "[GENERAL] Reset general error",
}

export const setGeneralError = createAction<any>(
	GeneralReducer.setGeneralError
);
export const resetGeneralError = createAction<void>(
	GeneralReducer.resetGeneralError
);
