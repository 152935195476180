declare global {
  interface Date {
    toBackendFormat: () => string;
    toFrontendFormat: () => string;
  }

  interface String {
    toCapitalize: () => string;
  }
}

Date.prototype.toBackendFormat = function () {
  if (!this) return '';

  const day = this.getDate().toLocaleString('it', { minimumIntegerDigits: 2 });
  const month = (this.getMonth() + 1).toLocaleString('it', {
    minimumIntegerDigits: 2,
  });
  const year = this.getFullYear();

  return `${year}-${month}-${day}`;
};

Date.prototype.toFrontendFormat = function () {
  if (!this) return '';

  const day = this.getDate().toLocaleString('it', { minimumIntegerDigits: 2 });
  const month = (this.getMonth() + 1).toLocaleString('it', {
    minimumIntegerDigits: 2,
  });
  const year = this.getFullYear();

  return `${day}/${month}/${year}`;
};

String.prototype.toCapitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export default global;
