import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "core/store";
import AuthService from "../auth.service";

const authReducer = (state: RootState) => state.auth;

export const authUserSelector = createSelector(
	authReducer,
	(state) => state.user
);
