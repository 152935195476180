import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authReducer } from 'core/auth/_redux/reducer';
import { privateReducer } from 'pages/private/_redux/reducer';

import { layoutReducer } from 'shared/design-system/_redux/reducer';
import { generalReducer } from './reducer';

export const store = configureStore({
  reducer: {
    general: generalReducer,
    layout: layoutReducer,
    auth: authReducer,
    private: privateReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface ThunkApiConfig {
  state: RootState;
}
