import { UserDTO } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import AuthService from 'core/auth/auth.service';
import { authLogin, authLogout, authMe, authResetPassword } from './actions';

interface AuthReducerType {
  user: UserDTO | undefined;
}

const initialState: AuthReducerType = {
  user: undefined,
};

export const authReducer = createReducer(initialState, builder => {
  builder
    .addCase(authLogin.fulfilled, (state, action) => {
      if (action.payload?.token) {
        AuthService.setAuthentication(action.payload.token);
      }
    })
    .addCase(authLogout, (state, action) => {
      AuthService.logout();
      return initialState;
    })
    .addCase(authMe.fulfilled, (state, action) => {
      state.user = action.payload;
    })
    .addCase(authResetPassword.fulfilled, (state, action) => {
      if (action.payload?.token) {
        AuthService.setAuthentication(action.payload.token);
      }
    });
});
